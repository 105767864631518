import EnglishLanguage from '../../components/localization/language/en/localize-core-emails809988fa70043c7924a30bf4a1bcddab02cc4b52ccc007c9514a3081051e9303.json'
import ArabicLanguage from '../../components/localization/language/ar-EG/localize-core-emails809988fa70043c7924a30bf4a1bcddab02cc4b52ccc007c9514a3081051e9303.json'
import { getSupportedLocales } from '../../services/utilityService';
import locales from '../../config/localesConfig';

const primaryLocaleCode = getSupportedLocales("primary");
const [languageCode, countryCode] = primaryLocaleCode.split('-');
const primaryLocaleName = locales[countryCode]?.[languageCode]?.language || 'English';
const isPrimaryLocaleArabic = primaryLocaleName.toUpperCase() === 'ARABIC';

//add language property here
//this will auto-populate the select component in LanguageSelector 
//make sure the json file of the added language is in the same format as the json file for English Language
export const languages = {
    [isPrimaryLocaleArabic ? 'ARABIC' : primaryLocaleName.toUpperCase()]: {
        label: isPrimaryLocaleArabic ? 'العربية' : primaryLocaleName.toUpperCase(),
        value: isPrimaryLocaleArabic ? 'ARABIC' : primaryLocaleName.toUpperCase(),
        language: isPrimaryLocaleArabic ? ArabicLanguage : EnglishLanguage,
    },
    ENGLISH: { 
        label: 'ENGLISH',
        value: 'ENGLISH',
        language: EnglishLanguage,
    }
}

//add case for added language here (property: value) and return the json file for language (property: language)
export const getLanguage = language => {
    switch(language){
        case languages[primaryLocaleName.toUpperCase()].value : return languages[primaryLocaleName.toUpperCase()].language;
        default: return languages.ENGLISH.language
    }
}

