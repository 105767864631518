import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import withFeatureFlags from './utils/FeatureFlagWrapper';
import PropTypes from 'prop-types';

const PaymentSuccessfulComponent = (props) => {
    const { flags } = props;

    const logo = useMemo(() => '/img/' + flags.logoName, [flags.logoName]);

    return (
        <Holder logo={logo}>
            <div
                style={{ padding: '10px', margin: '10px', backgroundColor: 'white', color: '#111' }}
            >
                <div>
                    <h2>Welcome Back!!</h2>
                    <p>
                        Your payment was successful and your plan has been renewed.
                        <br />
                        For enquiries, please don't hesitate to send a mail to{' '}
                        <a href="mailto:hellonigeria@getreliancehealth.com?Subject=Guardian Enquiry [from web app]">
                            hellonigeria@getreliancehealth.com
                        </a>
                        <br />
                        Thanks for choosing RelianceHMO.
                    </p>
                    <br />
                    <br />
                    <div style={{ fontSize: '22px' }}>
                        <div style={{ float: 'left' }}>
                            <Link to="/">Continue</Link>
                        </div>
                    </div>
                </div>
            </div>
        </Holder>
    );
};

export const Holder = (props) => {
    return (
        <div className="container">
            <div className="signup">
                <div className="signup__header">
                    <img src={props.logo} alt="logo" style={{ maxWidth: '20rem' }} />
                    <p>&nbsp;| For Guardians</p>
                </div>
                <div className="signup__form">{props.children}</div>
            </div>
        </div>
    );
};

Holder.propTypes = {
    logo: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};

// add prop types to Holder

export default withFeatureFlags(PaymentSuccessfulComponent);
