// FeatureFlagContext.js
import React, { createContext } from 'react';
import { isFeatureEnabled, getEnvValue } from '../components/utils/featureFlag';

export const FeatureFlagContext = createContext({});

export const FeatureFlagProvider = ({ children }) => {
    let allowedPlanIds = getEnvValue('allowed_plan_ids') || [];

    if (allowedPlanIds === 'null') {
        allowedPlanIds = [];
    }

    if (allowedPlanIds?.length) {
        allowedPlanIds = allowedPlanIds.includes(',')
            ? allowedPlanIds.split(',').map((item) => +item)
            : [+allowedPlanIds];
    }

    const featureFlags = {
        flags: {
            logoName: getEnvValue('logo_name'),
            languageOptionsEnabled: isFeatureEnabled('language_options'),
            domain: getEnvValue('domain'),
            companyName: getEnvValue('company_name'),
            enrolleeDashboardUrl: getEnvValue('enrollee_dashboard_url'),
            countryLocale: getEnvValue('country_locale'),
            allowedPlanIds
        }
    };

    return (
        <FeatureFlagContext.Provider value={featureFlags}>{children}</FeatureFlagContext.Provider>
    );
};
